import Dropdown from "components/dropdown";
import { FiAlignJustify } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { BsChevronRight } from "react-icons/bs";
import { RiMoonFill, RiSunFill } from "react-icons/ri";
import { IoMdNotificationsOutline } from "react-icons/io";
import avatar from "assets/img/avatars/dummy.png";
import UserIcon from "components/icons/UserIcon";
import Logout from "components/icons/Logout";
import DownArrow from "components/icons/DownArrow";
import EditProfile from "components/navbar/EditProfile";
import { useDispatch, useSelector } from "react-redux";
import { logOut } from "features/auth/authSlice";
import { useState } from "react";

const Navbar = (props) => {
  const { onOpenSidenav, brandText } = props;
  const [darkmode, setDarkmode] = useState(false);
  // const [show, setShow] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { firstName, lastName, profilePic, role } = useSelector(
    (state) => state.auth
  );

  return (
    <nav className="3xl:mr-18 sticky top-4 z-40 flex flex-row flex-wrap items-start justify-between rounded-xl p-2 backdrop-blur-xl dark:bg-[#0b14374d]">
      <div className="ml-[6px] max-w-[80%]">
        <div className="shrink text-[33px] capitalize text-navy-700 dark:text-white">
          <p className="max-w-full truncate font-semibold hover:text-navy-700 dark:hover:text-white">
            {brandText}
          </p>
        </div>
      </div>

      <div className="relative mt-[3px] flex h-[61px] w-[355px] flex-grow items-center justify-around gap-4 py-2 md:flex-grow-0 md:gap-1 xl:w-fit xl:gap-4">
        <span
          className="flex cursor-pointer text-xl text-gray-600 dark:text-white xl:hidden"
          onClick={onOpenSidenav}
        >
          <FiAlignJustify className="h-5 w-5" />
        </span>
        {/* start Notification */}
        <Dropdown
          button={
            <p className="cursor-pointer">
              <IoMdNotificationsOutline className="h-5 w-5 text-[#787486] dark:text-white" />
            </p>
          }
          animation="origin-[65%_0%] md:origin-top-right transition-all duration-300 ease-in-out"
          children={
            <div className="flex w-[360px] flex-col gap-3 rounded-[20px] bg-white p-4 shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:text-white dark:shadow-none sm:w-[460px]">
              <div className="flex items-center justify-between">
                <p className="text-base font-bold text-navy-700 dark:text-white">
                  Notification
                </p>
                <p className="cursor-pointer text-sm font-bold text-navy-700 dark:text-white">
                  Mark all read
                </p>
              </div>

              <button className="flex w-full items-center border-t pt-2">
                <div className="flex h-full w-full flex-col justify-center rounded-lg px-1 text-sm">
                  <p className="mb-1 text-left text-base font-bold text-gray-900 dark:text-white">
                    Coming Soon
                  </p>
                  <p className="font-base text-left text-xs text-gray-900 dark:text-white">
                    This feature will help you to get notified about changes
                  </p>
                </div>
              </button>
            </div>
          }
          classNames={"py-2 top-4 -left-[230px] md:-left-[440px] w-max"}
        />
        <div
          className="cursor-pointer "
          onClick={() => {
            if (darkmode) {
              document.body.classList.remove("dark");
              setDarkmode(false);
            } else {
              document.body.classList.add("dark");
              setDarkmode(true);
            }
          }}
        >
          {darkmode ? (
            <RiSunFill className="h-4 w-4 text-gray-600 dark:text-white" />
          ) : (
            <RiMoonFill className="h-4 w-4 text-[#787486] dark:text-white" />
          )}
        </div>
        {/* Profile & Dropdown */}
        <div className="flex gap-3">
          <img
            className="h-10 w-10 rounded-full object-fill md:h-10 md:w-10"
            src={profilePic ? profilePic : avatar}
            alt="Profile"
          />
          <div className="hidden xl:block">
            <p className="font-semibold text-[#787486] dark:text-white">
              {firstName ? firstName + " " + lastName : "User Name"}
            </p>
            <p className="text-sm text-[#787486] dark:text-white">
              {role.name}
            </p>
          </div>
        </div>
        <Dropdown
          button={<DownArrow className="h-3 w-3.5 cursor-pointer" />}
          classNames={"py-2 top-8 -left-[260px] xl:-left-[250px] w-max"}
          children={
            <div className="flex w-72 flex-col justify-start rounded-lg bg-white bg-cover bg-no-repeat shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:text-white dark:shadow-none">
              <div className="p-4">
                <div className="flex items-center gap-2">
                  <img
                    className="h-16 w-16 rounded-full object-fill"
                    src={profilePic ? profilePic : avatar}
                    alt="Profile"
                  />
                  <div className="ml-3 mt-2">
                    <p className="max-w-lg text-sm font-bold text-navy-700 dark:text-white">
                      {firstName ? firstName + " " + lastName : "User Name"}
                    </p>
                    <p className="text-sm font-normal">{role.name}</p>
                  </div>
                </div>
              </div>
              <div className="h-px w-full bg-gray-200 dark:bg-white/20 " />

              <div className="flex flex-col p-4">
                <Dropdown
                  id="profile"
                  button={
                    <div
                      className="flex min-w-[250px] cursor-pointer  items-center justify-between rounded-lg p-3 hover:bg-[#e6e6e659]"
                      onClick={() => {
                        window.document
                          .getElementById("profile")
                          .classList.toggle("hidden");
                      }}
                    >
                      <div className="flex items-center gap-3 fill-white stroke-black-500 dark:fill-white dark:stroke-white">
                        <UserIcon />
                        <p className="text-gray-800 dark:text-white hover:dark:text-white">
                          My Profile
                        </p>
                      </div>
                      <BsChevronRight />
                    </div>
                  }
                  classNames="py-2 w-max bg-black -left-[100%] xl:-left-60 -top-36 hidden"
                  children={<EditProfile setShowProfileForm={() => {}} />}
                />

                <button
                  className="mt-3 flex cursor-pointer items-center gap-3 rounded-lg stroke-red-700 p-3 hover:bg-[#e6e6e659] dark:stroke-white"
                  onClick={() => {
                    dispatch(logOut());
                    navigate("/auth/sign-in");
                  }}
                >
                  <Logout />
                  <p className="flex gap-2 text-sm font-medium transition duration-150 ease-out  hover:ease-in">
                    Log Out
                  </p>
                </button>
              </div>
            </div>
          }
        />
      </div>
    </nav>
  );
};

export default Navbar;
