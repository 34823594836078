import logoFull from "assets/img/layout/MoLS-Logo-Full.png";
import useAxios from "axios-hooks";
import Loading from "components/loading";
import Error from "components/error";
import PasswordInputField from "components/fields/PasswordInputField";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

function PasswordResetConfirm() {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [success, setSuccess] = useState(false);
  const params = useParams();
  const uid = params["*"].split("/")[0];
  const token = params["*"].split("/")[1];
  const navigate = useNavigate();
  const [{ loading, error }, resetPassword] = useAxios(
    {
      url: "/api/v1/users/reset_password_confirm/",
      method: "POST",
    },
    { manual: true }
  );

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      console.log("passwords do not match", password, confirmPassword);
      document
        .getElementById("newPasswordConfirm")
        .setAttribute("state", "error");
      return;
    }
    try {
      resetPassword({
        data: {
          uid: uid,
          token: token,
          new_password: password,
        },
      }).then(() => {
        setPassword("");
        setConfirmPassword("");
        setSuccess(true);
        setTimeout(() => {
          navigate("/auth/sign-in");
        }, 1000);
      });
    } catch (error) {
      console.log("error", error);
    }
  };
  return (
    <div className="h-screen w-screen bg-[#F0F2FF]">
      {/* Success Modal */}
      {success && <SuccessModal navigate={navigate} />}
      <div className="mx-auto h-full w-fit rounded-lg p-6 font-poppins xl:pt-[10%]">
        <img src={logoFull} alt="logo" className="mx-auto mb-1 h-14 w-60" />
        <h2 className="mb-10 text-center text-lg text-blue-800 ">
          Project Management
        </h2>
        {error && <Error error={error} />}
        {loading && <Loading />}

        {!loading && (
          <div>
            <p className="mb-5 max-w-md text-center font-semibold text-navy-600">
              Enter new password that you will remember
            </p>
            <form onSubmit={handleSubmit}>
              <PasswordInputField
                variant="auth"
                label="New Password"
                placeholder="Min. 8 characters"
                id="newPassword"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                autoComplete="off"
                required
              />
              <p className="mb-5 ml-1 pl-3 text-sm text-gray-600 dark:text-gray-300">
                Must have at least two characters, and don't start with number
              </p>

              <PasswordInputField
                variant="auth"
                label="Confirm Password"
                placeholder="Min. 8 characters"
                id="newPasswordConfirm"
                type="password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                autoComplete="off"
                required
              />
              <p className="mb-9 ml-1 pl-3 text-sm text-gray-600 dark:text-gray-300">
                Must have at least two characters, and don't start with number
              </p>
              <button
                type="submit"
                className="w-full rounded-lg bg-blue-500 py-3 px-4 font-bold text-white hover:bg-blue-700"
              >
                Confirm
              </button>
            </form>
          </div>
        )}
      </div>
    </div>
  );
}

const SuccessModal = ({ navigate }) => {
  return (
    <div className="fixed top-0 left-0 z-50 flex h-[80%] w-full items-center justify-center bg-opacity-50 backdrop-blur-lg">
      <div className="rounded bg-white p-6 shadow-md">
        <h2 className="mb-4 text-lg font-bold">Password Reset Successful</h2>
        <p className="mb-4">
          Your password has been successfully reset. You can now log in with
          your new password.
        </p>
        <button
          className="float-right mt-3 rounded bg-blue-500 py-2 px-4 font-bold text-white hover:bg-blue-700"
          onClick={() => {
            navigate("/auth/sign-in");
          }}
        >
          Close
        </button>
      </div>
    </div>
  );
};
export default PasswordResetConfirm;
