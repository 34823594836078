import SidebarCard from "components/sidebar/componentsrtl/SidebarCard";
import logo from "assets/img/layout/MoLS-logo.png";
import LeftArrow from "components/icons/LeftArrow";
import SidebarLinks from "components/sidebar/components/Links";
import { BsChevronDoubleLeft } from "react-icons/bs";
import { higherOfficialRoutes } from "routes";

const Sidebar = ({ open, onClose, setShowModal }) => {
  return (
    <div
      className={`sm:none duration-175 linear fixed !z-50 flex min-h-full flex-col bg-white pb-10 shadow-2xl shadow-white/5 transition-all dark:!bg-navy-800 dark:text-white md:!z-50 lg:!z-50 xl:!z-0 ${
        open ? "translate-x-0" : "-translate-x-96"
      }`}
    >
      <span
        className="absolute top-12 right-4 block cursor-pointer xl:hidden"
        onClick={onClose}
      >
        <BsChevronDoubleLeft size={20} color="#787486" />
      </span>

      <div
        className={`ml-[36px] mr-12 mt-[45px] flex items-center justify-between`}
      >
        <div className="mt-1 ml-1 flex h-2.5 items-center gap-2 font-poppins text-xl  font-semibold text-[#787486] dark:text-white">
          <img src={logo} className="w-11" alt="logo" />
          Project <span className="font-semibold">M.</span>
        </div>
        <LeftArrow className="invisible ml-8" />
      </div>
      <div className="mt-[58px] mb-7 h-px bg-gray-300 dark:bg-white/30" />
      {/* Nav item */}

      <ul className="mb-auto pt-1">
        <SidebarLinks routes={higherOfficialRoutes} />
        {/* Projects list */}
        <div className="mt-10 px-10">
          <hr className="my-4" />
          <div className="mr-5 ml-2 flex items-center justify-between">
            <h1 className="my-4 ml-2 font-poppins text-sm font-semibold uppercase text-[#787486]">
              My Projects
            </h1>
          </div>
        </div>
      </ul>

      {/* Free Horizon Card */}
      <div className="flex justify-center">
        <SidebarCard />
      </div>

      {/* Nav item end */}
    </div>
  );
};

export default Sidebar;
